import * as React from 'react';
import styled from 'styled-components';
import { fonts } from '../common/styles';

const Salutation = styled.div`
  font-size: 20px;
  letter-spacing: 1px;
  font-family: ${fonts.nothingYouCouldDo};
  margin-bottom: 4px;
`;

export default Salutation;
