import * as React from 'react';
import styled from 'styled-components';
import PageContent from '../components/PageContent';
import Layout from '../components/Layout';
import Salutation from '../components/Salutation';

const StyledPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .message {
    border-radius: 8px;
    text-align: center;
    border: 1px solid tomato;
    padding: 24px 32px;
  }
`;

export function RiverleighRoom() {
  return (
    <Layout subPageTitle='Riverleigh Room'>
          <PageContent>
              <StyledPage>
                <div className="message">
                  <Salutation>The Riverleigh Room</Salutation>
                  <div>Coming Soon to the M.O.V.</div>
                </div>
              </StyledPage>
          </PageContent>
    </Layout>
  );
}

export default RiverleighRoom;
